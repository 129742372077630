<template>
  <div class="coupon-detail-page">
    <div class="toolbar">
      <div class="is-flex justify-flex-start align-items-center" @click="$router.go(-1)">
        <img class="chevron" src="@/assets/icon/x.svg" height="20" width="20"/>
      </div>
      <!-- <UsedBadge
        :used-time="systemCoupon && systemCoupon.used_limit_time || 0"
        :limit-time="systemCoupon && systemCoupon.num_time || 0"
      /> -->
    </div>
    <div class="content-section">
      <div class="img-product-wrapper">
        <div class="img-product">
          <img :src="systemCoupon.url"
            @error="replaceByDefault"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="is-flex justify-space-between align-items-center">
          <div>
            <div class="has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5">{{systemCoupon && systemCoupon.collect_method || ''}}</div>
          </div>
          <!-- <div class="_fs-12 has-text-black40">{{$t('common.couponId', { couponId: systemCoupon && systemCoupon.code || ''})}}</div> -->
        </div>
        <div class="_fs-24 has-text-black has-margin-top-25">{{ $i18n.locale === 'th' ? systemCoupon && systemCoupon.name || '' : systemCoupon && systemCoupon.name_en || ''}}</div>
        <div class="_fs-12 has-text-black40 has-margin-top-20">{{$t('couponDetail.expirationDate', {expiredAt: expiredAt || ''})}}</div>
        <div class="_fs-16 has-text-black has-margin-top-20">
          <p v-html="$i18n.locale === 'th' ? systemCoupon && systemCoupon.description || '' : systemCoupon && systemCoupon.description_en || ''" class="render"></p>
        </div>

        <div class="_fs-12 has-text-black40 has-margin-top-15">{{$t('couponDetail.condition')}}</div>
        <div class="_fs-16 has-text-black has-margin-top-10"><p v-html="$i18n.locale === 'th' ? systemCoupon && systemCoupon.condition || '' : systemCoupon && systemCoupon.condition_en || ''" class="render"></p></div>
      </div>
    </div>
    <div class="is-flex justify-center action-section">
      <RoundButton @click="collect()">{{$t('couponDetail.claim')}}</RoundButton>
    </div>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isClaimedCouponModalActive"
      :can-cancel="false"
    >
      <ClaimedCouponModal
        :coupon-id="systemCoupon && systemCoupon.code || ''"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import RoundButton from '@/components/base/RoundButton.vue'
// import UsedBadge from '@/components/coupon/UsedBadge.vue'
import ClaimedCouponModal from '@/components/modal/ClaimedCouponModal.vue'
import { mapActions, mapState } from 'vuex'
import { mapWaitingActions } from 'vue-wait'

export default {
  data () {
    return {
      isClaimedCouponModalActive: false,
      errorMessage: ''
    }
  },
  components: {
    RoundButton,
    // UsedBadge,
    ClaimedCouponModal
  },
  computed: {
    ...mapState('Coupon', ['systemCoupon']),
    coupon () {
      return (this.systemCoupon && this.systemCoupon.coupon) || {}
    },
    memberLevel () {
      return (this.systemCoupon && this.systemCoupon.member_card && this.systemCoupon.member_card.name) || ''
    },
    expiredAt () {
      if (this.systemCoupon && this.systemCoupon.expired_at) {
        return moment(this.systemCoupon.expired_at).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm')
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions({
      fetchSystemCouponById: 'Coupon/fetchSystemCouponById',
      collectCoupon: 'Coupon/collectCoupon',
      clearSystemCoupon: 'Coupon/clearSystemCoupon'
    }),
    ...mapWaitingActions('Coupon', {
      fetchSystemCouponById: 'app loading',
      collectCoupon: 'app loading'
    }),
    async collect () {
      const id = this.$route.params.id.toString()
      try {
        await this.collectCoupon({
          coupon: id
        })
        this.isClaimedCouponModalActive = true
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { data } = error.response
          if (data && data.includes('coupon already exist')) {
            this.errorMessage = 'coupon already exist'
          } else {
            this.errorMessage = data
          }
        } else {
          this.errorMessage = error.toString()
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    replaceByDefault (e) {
      e.target.src = require('@/assets/img/coupon-default.png')
    }
  },
  created () {
    this.clearSystemCoupon()
  },
  async mounted () {
    const id = this.$route.params.id.toString()
    await this.fetchSystemCouponById({
      id: id
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.coupon-detail-page {
  min-height: 100vh;
  padding-top: 18px;
  /* position: relative; */
  .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .content-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding-bottom: 100px;
  }
  .img-product-wrapper {
    width: 100%;
    min-height: 200px;
    border-bottom: 1px solid $greyd0;
  }
  .img-product {
    background: $greyd0;
    width: 100%;
    padding-top: 56%;
    position: relative;
    background-size: cover;
    background-position: center top;
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-wrapper {
    padding: 24px 20px;
    .render {
      word-wrap: break-word;
      /deep/ ol {
        list-style-position: inside;
      }
      /deep/ ul {
        list-style-position: inside;
        list-style-type: disc;
      }
    }
  }
  .action-section {
    position: fixed;
    bottom: 0;
    padding: 20px;
    width: 100%;
    background: white;
    z-index: 3;
  }
}
</style>
